import axios from "axios";

const apiUrl = "https://api.verus.services:443/";

async function getCurrencyAddress(name: string) {
  const payload = {
    jsonrpc: "1.0",
    id: "curltest2",
    method: "getcurrency",
    params: [name],
  };
  const headers = { "Content-Type": "application/json" };

  try {
    const response = await axios.post(apiUrl, payload, { headers });
    const data = response.data;
    if (data.result && data.result.currencyid) {
      return data.result.currencyid;
    }
  } catch (error) {
    console.error("Error in getCurrencyAddress:", error);
  }
  return null;
}

async function getRoutes(tocurrency: string, fromcurrency: string) {
  const payload = {
    jsonrpc: "1.0",
    id: "curltest2",
    method: "listcurrencies",
    params: [],
  };
  const headers = { "Content-Type": "application/json" };

  try {
    const response = await axios.post(apiUrl, payload, { headers });
    const data = response.data;
    if (data.result) {
      const validRouteList = [];
      for (const entry of data.result) {
        const beststate = entry.bestcurrencystate;
        if (beststate.reservecurrencies) {
          const basketname = entry.currencydefinition.name;
          const basketiaddr = beststate.currencyid;
          const list_of_reserves = beststate.reservecurrencies.map(
            (res: any) => res.currencyid
          );
          if (
            list_of_reserves.includes(tocurrency) &&
            list_of_reserves.includes(fromcurrency)
          ) {
            validRouteList.push([basketname, basketiaddr]);
          }
        }
      }
      return validRouteList.length > 0 ? validRouteList : null;
    }
  } catch (error) {
    console.error("Error in getRoutes:", error);
  }
  return null;
}

export async function estimateConversion(
  fromcurrency: string,
  tocurrency: string,
  amount: number
) {
  const payload = {
    jsonrpc: "1.0",
    id: "curltest",
    method: "estimateconversion",
    params: [
      {
        currency: fromcurrency,
        convertto: tocurrency,
        amount,
      },
    ],
  };
  const headers = { "Content-Type": "application/json" };

  try {
    let response = await axios.post(apiUrl, payload, { headers });
    let data = response.data;

    if (data.result && data.result.estimatedcurrencyout) {
      return [data.result.estimatedcurrencyout];
    } else if (data.error) {
      const error_message = data.error.message || "";
      if (
        error_message.includes(
          "If source currency is specified, it must be valid."
        )
      ) {
        console.log("invalid input currency");
      } else if (
        error_message.includes(
          "Source currency cannot be converted to destination"
        ) ||
        error_message.includes(
          "vETH must be a fractional currency or prior to start block to estimate a conversion price"
        )
      ) {
        const fromcurrencyaddress = await getCurrencyAddress(fromcurrency);
        const tocurrencyaddress = await getCurrencyAddress(tocurrency);
        const validRoutes = await getRoutes(
          fromcurrencyaddress,
          tocurrencyaddress
        );
        if (!validRoutes) {
          return ["no direct route found. try converting to VRSC first."];
        } else {
          const routes = [];
          for (const route of validRoutes) {
            const basketname = route[0];
            const via = route[1];
            (payload.params[0] as any).via = via;
            response = await axios.post(apiUrl, payload, { headers });
            data = response.data;
            routes.push(
              basketname +
                ": " +
                data.result.estimatedcurrencyout +
                " " +
                tocurrency
            );
          }
          return routes;
        }
      } else {
        return ["A data error occurred."];
      }
    } else {
      return ["Another error occurred."];
    }
  } catch (error) {
    console.error("Error in estimateConversion:", error);
  }
}
