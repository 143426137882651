import { VRSC_CURRENCY_ID } from "../../../components/CurrencyTable/Table";
export const getCurrencyType = (
  currencyId: any,
  reserves: Array<any>,
  proof: number
) => {
  if (currencyId === VRSC_CURRENCY_ID) return "";
  if (reserves?.length > 1 && proof != 3) return "Basket";
  if (proof === 3) return "Bridge";
  return "Token";
};
