export default function generatePayload(
  command: string,
  id: string,
  parameters: any
) {
  const payload: Record<string, string | any[]> = {};
  payload.jsonrpc = "2.0";
  payload.id = id;
  payload.method = command;
  payload.params = parameters;
  return payload;
}
