import Modal from "../Modal/Modal";
import { get_options } from "~/utils/get_options";
import { createMemo, For } from "solid-js";
import { OptionDefinitionType } from "~/utils/get_options";
import { routeData } from "~/routes/currencies";
import { Tooltip } from "../Tooltip/Tooltip";
import { useRouteData } from "solid-start";
import { VRSC_CURRENCY_ID } from "../CurrencyTable/Table";
import { currency_notes } from "~/utils/currency_notes";
import linkifyHtml from "linkify-html";
import { getRating } from "../../archive/EconomyTable/utils/getRating";
import { onCleanup } from "solid-js";
import { formatter } from "../../archive/EconomyTable/EconomyTable";

interface CurrencyModalProps {
  closeModal: Function;
  currency: any;
}
let notes_container;

function CurrencyModal(props: CurrencyModalProps) {
  const { currency } = props;
  const { currency_iaddr: id, name, options, proof, supply: curr_supply, reservecurrencies, idregfee, rescurrlist, onchainprice } =
    currency() || {};
  console.log(currency())

  const { currency_data } = useRouteData<typeof routeData>();

  const linkify_opts = { target: "_blank" }
  const notes = currency_notes[name?.toLowerCase()] ? linkifyHtml(currency_notes[name?.toLowerCase()], linkify_opts) : '';

  const optionString = createMemo(() => {
    const o = get_options(options, []);
    let str = "";
    (o as OptionDefinitionType[])?.map((opt) => {
      str += `${opt.option_string} \n\n`;
      return;
    });
    return str;
  });

  const opts = get_options(options, []);

  const vrscReserves = reservecurrencies?.find(
    (r: any) => r.currencyid === VRSC_CURRENCY_ID
  );
  const { rating, description } = getRating(
    options,
    proof,
    vrscReserves ? vrscReserves.weight : 0,
    reservecurrencies?.length > 1
  );
  const res_list = createMemo(() => {
    return reservecurrencies?.map((item: any, index: number) => {
      return {
        ...item,
        name: rescurrlist[index]
      }

    })
  })

  return (
    <Modal
      hideBackground
      closeModal={props.closeModal}
      content={
        <div class="w-full">
          <div class="flex justify-start items-center pagefooter-background rounded-t-lg pl-6 py-6">
            <h2 class="text-2xl font-semibold text-white py-4 capitalize">{name}</h2>
          </div>

          <div class=" web3container-background p-6 rounded-b-lg text-white text-left w-full max-h-[75vh] overflow-y-scroll min-w-[25rem]">
            <div class="py-2 flex">
              <span class="font-bold">Options:</span>
              <span
                class="cursor-pointer text-blue-400 ml-2"
                title="Learn more here: www.linktoverusdocs.com"
              >
                <Tooltip placement='right' text={optionString()}>
                  <div>{options}</div>
                </Tooltip>
              </span>
            </div>
            <div class="py-2">
              <span class="font-bold">Proof Protocol:</span>
              <span
                class="cursor-pointer text-blue-400 ml-2"
                title="if 2, currency can be minted by whoever controls the ID"
              >
                {proof}
              </span>
            </div>
            {onchainprice ? (
              <div class="py-2">
                <span class="font-bold">Market Cap: </span>
                <span
                  class="cursor-pointer text-blue-400 ml-2"
                  title="if 2, currency can be minted by whoever controls the ID"
                >
                  {formatter.format(onchainprice * curr_supply)}
                </span>
              </div>
            ) : (<></>)}

            {
              VRSC_CURRENCY_ID === id && (
                <div class="py-2">
                  <span class="font-bold">Total Supply:</span>
                  &nbsp;
                  <span>83540000</span>
                </div>

              )
            }
            <div class="py-2">
              <span class="font-bold">Circulating Supply:</span>
              &nbsp;
              <span>{(currency().supply).toLocaleString('en-US')}</span>
            </div>

            {VRSC_CURRENCY_ID !== id && (<div class="py-2">
              <span class="font-bold">Reserve Currency:</span>
              &nbsp;
              <span>{reservecurrencies?.length > 0 ? "✅" : "❌"}</span>
            </div>)}

            {reservecurrencies?.length > 0 && (
              <div class="py-2">
                <span class="font-bold">
                  Reserve Currencies (Name | Amount | % Weight):
                </span>

                <ul class="list-disc list-inside text-white ml-4">
                  <For each={res_list()}>
                    {(currency) => {

                      const { currencyid, priceinreserve, reserves, weight, name } =
                        currency;

                      return (
                        <li>
                          {reserves.toFixed(2)}{" "} {name} | {weight * 100}%
                        </li>
                      );
                    }}
                  </For>
                </ul>
              </div>
            )}

            {VRSC_CURRENCY_ID !== id && (
              <div class="py-2">
                <span class="font-bold">Supports subIDs:</span>
                &nbsp;
                <span>{idregfee > 0 ? "✅" : "❌"}</span>
              </div>
            )}

            <div class="py-2">
              <span class="font-bold">ID Registration Fees:</span>
              &nbsp;
              <span>
                {idregfee} {name}
              </span>
            </div>

            <div class="py-2">
              <span class="font-bold">Supports referrals:</span>
              &nbsp;
              <span>
                {((opts as OptionDefinitionType[])?.some(
                  (o: any) => o.option === 8
                ) || VRSC_CURRENCY_ID === id)
                  ? "✅"
                  : "❌"}
              </span>
            </div>

            <div class="py-2">
              <span>Flags:</span>
              <Tooltip text={description as any} placement="right">
                {currency.currency_id === VRSC_CURRENCY_ID ? (
                  <img src="/vrsc_logo.png" height="20px" width="20px" />
                ) : (
                  `${rating}`
                )}
              </Tooltip>
            </div>

            <div class="py-2 flex flex-col " >
              <span>Notes:</span>
              <div class="[&>a]:text-blue-400 max-w-[30rem]" innerHTML={notes}></div>
            </div>
          </div>
        </ div>
      }
    />
  );
}

export default CurrencyModal;
