import { Layout } from "~/Layout/Layout";
import Chart from "~/components/Chart/Chart";

export function routeData() { }

export default function Market() {
    return (
        <Layout onlyNav>
            <div class="h-full w-full container flex flex-col items-center justify-center !mt-24">
                <Chart />
            </div>
        </Layout>

    );
}
