export type OptionDefinitionType = {
  option_string: string;
  option: number;
};

export const option_definition: Record<number, string> = {
  1: "Has Reserves",
  2: "Only Controlling ID creates subIDs",
  8: "Refferrals and Discounts enabled",
  16: "Referrals required for subID creation",
  32: "Simple token currency with no reserves",
  2048: "NFT controlled root Verus ID",
};

export const get_options = (
  _option: number,
  selected_options: OptionDefinitionType[] = []
): OptionDefinitionType[] | Function | undefined => {
  const current_options = Object.keys(option_definition)
    .sort((a: any, b: any) => b - a)
    .map((a) => parseInt(a));
  for (let option of current_options) {
    if (option <= _option) {
      if (Math.floor(_option / option) === 1) {
        selected_options.push({
          option_string: option_definition[option],
          option,
        });
        const remainder = _option % option;
        if (remainder === 0) return selected_options;
        if (current_options.includes(remainder)) {
          selected_options.push({
            option_string: option_definition[remainder],
            option: remainder,
          });
          return selected_options;
        } else {
          return get_options(remainder, selected_options);
        }
      } else {
        selected_options.push({
          option_string: "No options available",
          option: _option,
        });
        return selected_options;
      }
    }
  }
};
