import { For } from "solid-js";
import { Container } from "./Table.style";
import { Accessor } from "solid-js";

type TableHeaderProps = {
    headings: string[];
}
export default function TableHeader(props: TableHeaderProps) {
    const { headings } = props;
    return (
        <thead class="border-b-2 border-slate-600">
            <tr class="space-x-5">
                <For each={headings}>
                    {(heading) => (
                        <th class={`${heading === 'Name' ? 'text-left' : 'text-center'} text-sm text-slate-700 whitespace-nowrap`}>{heading}</th>
                    )}
                </For>
            </tr>
        </thead>
    );
}
