import { Pool } from "pg";
import server$ from "solid-start/server";

export const fetchPool = server$(() => {
  const pool = new Pool({
    user: process.env.SERVER_SOLID_APP_DB_USER,
    host: process.env.SERVER_SOLID_APP_DB_HOST,
    database: process.env.SERVER_SOLID_APP_DB_DATABASE,
    password: process.env.SERVER_SOLID_APP_DB_PASSWORD,
    port: parseInt(process.env.SERVER_SOLID_APP_DB_PORT as string),
  });
  return pool;
});
