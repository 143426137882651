import { createRouteData, useRouteData } from "solid-start";
import { fetchPool } from "~/utils/fetchPool";
import { Layout } from "~/Layout/Layout";
import { Tooltip } from "~/components/Tooltip/Tooltip";
import vrscData from '../data/verus_info_data.json';
import vrscEcosystem from '../data/verus_ecosystem_data.json';
import { For } from "solid-js";
import { VRSC_CURRENCY_ID } from "~/components/CurrencyTable/Table";

export function routeData() {
  const id_data = createRouteData(async () => {
    const pool = await fetchPool();
    const client = await pool.connect();
    const stats = await client.query("SELECT * FROM stats");
    const tokenCount = await client.query("SELECT COUNT(*) FROM currencies");
    const currencies = await client.query(`SELECT * FROM currencies`);
    const vrsc = currencies.rows.find((c) => c.currency_iaddr === VRSC_CURRENCY_ID);
    client.release();
    return {
      stats: stats.rows[0],
      num_tokens: tokenCount.rows[0].count,
      vrsc_supply: vrsc?.supply
    };
  });
  return { id_data };
}

export default function Home() {

  const { id_data }: any = useRouteData<typeof routeData>();

  return (
    <Layout>
      <main class="w-full pagefooter-background text-white text-xl p-10 lg:p-10 "  >
        <div class="flex flex-col w-full lg:w-[75%] ml-[3%]">
          <div class="w-full flex flex-col lg:flex-row flex-wrap">
            <For each={vrscData}>
              {(data) => {
                return (
                  <div class=" w-full lg:w-[40%] mx-[2%] my-10 flex items-start justify-start flex-col space-y-3 relative">
                    <div class="absolute h-[100px] w-[100px] -top-5 -left-10 pointer-events-none">
                      <img src='/blue_accent.png' class="object-cover" />
                    </div>
                    <div class="text-[25px] font-bold">
                      {data.title}
                    </div>
                    <div class="text-left text-[18px] lg:w-[400px] font-extralight">
                      {data.content}
                    </div>
                    <a href={data.link} class="text-link-color">
                      Enter
                    </a>
                  </div>
                )
              }}
            </For>
          </div>
        </div>

        <div class="flex flex-col items-start w-full lg:w-[75%] my-16 lg:my-10 h-full mx-[7%] lg:mx-[4%] relative">
          <div class="absolute h-[150px] w-[150px] -top-16 -left-12">
            <img src='/orange_accent.png' class="object-cover" />
          </div>

          <div class="text-[22px] lg:text-[32px] font-semibold text-left">
            Verus Statistics:
          </div>
          <div class="flex flex-wrap flex-col lg:flex-row w-full lg:w-full gap-y-8 gap-x-16 -ml-2.5 lg:ml-0 mt-10 lg:mt-10 text-[22px] font-extralight">
            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                IDs Created:
              </span>
              <span class="text-link-color">
                {id_data()?.stats.totalsubids + id_data()?.stats.totalrootids}
              </span>
            </div>
            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                Hash Power (GH):
              </span>
              <span class="text-link-color">
                {id_data()?.stats.netgigahashes}
              </span>
            </div>
            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                Tokens:
              </span>
              <span class="text-link-color">
                {id_data()?.num_tokens}
              </span>
            </div>
            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                Last Block:
              </span>
              <span class="text-link-color">
                {id_data()?.stats.blockheight.toFixed(0)}
              </span>
            </div>
            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                PBaaS:
              </span>
              <span class="text-link-color">
                2
              </span>
            </div>
            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                Avg Reward:
              </span>
              <span class="text-left text-link-color whitespace-nowrap">
                {id_data()?.stats.avgblockreward.toFixed(2)} {' '} VRSC
              </span>
            </div>

            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                Staking Supply:
              </span>
              <span class="whitespace-nowrap text-link-color">
                <Tooltip placement="left" text={`${new Intl.NumberFormat('en').format(id_data()?.stats.stakingsupply.toFixed(2))} VRSC`}>
                  <span class="whitespace-nowrap">
                    {new Intl.NumberFormat('en', { notation: 'compact' }).format(id_data()?.stats.stakingsupply.toFixed(2))}  {' '} VRSC
                  </span>
                </Tooltip>
              </span>
            </div>

            <div class="w-fit lg:min-w-[400px] flex justify-between space-x-3 bg-[rgba(0,0,0,0.25)] p-3 px-2 rounded">
              <span class="whitespace-nowrap">
                Current Supply:
              </span>
              <span class="whitespace-nowrap text-link-color">
                ~ 77M VRSC
                {/* <Tooltip placement="left" text={`${new Intl.NumberFormat('en').format(id_data()?.vrsc_supply.toFixed(2))} VRSC`}>
                  <span class="whitespace-nowrap">
                    {new Intl.NumberFormat('en', { notation: 'compact' }).format(id_data()?.vrsc_supply.toFixed(2))}  {' '} VRSC
                  </span>
                </Tooltip> */}
              </span>
            </div>

          </div>
        </div>
      </main>
      <main class="w-full pagefooter-background relative z-0 text-white text-xl p-10 lg:p-32 lg:py-20">
        <div class="flex flex-col w-full">
          <div class="flex items-start flex-col space-y-8 mb-8">
            <div>
              <span class="text-[59px] font-semibold">Verus</span> &nbsp;
              <span class="text-[35px] font-extralight">The internet of value:</span>
            </div>
            <div class="text-left text-[28px] font-extralight leading-[40px]">
              Learn more about the world’s most scalable, credibly neutral public infrastructure:
            </div>
          </div>
          <div class="w-full flex flex-col lg:flex-row flex-wrap">
            <For each={vrscEcosystem}>
              {(eco) => {
                return (
                  <div class=" w-full lg:w-[40%] mx-[4%] my-10 flex items-start justify-start flex-col space-y-6 relative">

                    <div class="absolute h-[100px] w-[100px] -top-5 -left-10 pointer-events-none">
                      <img src='/blue_accent.png' class="object-cover" />
                    </div>
                    <div class="text-[40px] font-extralight">
                      {eco.title}
                    </div>
                    <div class="text-left text-[24px] lg:w-[400px] font-extralight">
                      {eco.content}
                    </div>
                    <For each={eco.link}>
                      {(link, i) => {
                        return (
                          <a href={link.url} target="_blank" class="text-link-color text-left">
                            {link.label}
                          </a>
                        )
                      }}
                    </For>
                  </div>
                )
              }}
            </For>
          </div>
        </div>
      </main>
    </Layout>
  );
}
