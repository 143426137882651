const fetchRpcUrl = (): { rpc_url: string; fallback: string } => {
  let rpc_url;

  switch (process.env.SERVER_SOLID_APP_ENVIRONMENT) {
    case "development":
      rpc_url = process.env.SERVER_SOLID_APP_DEV_RPC_URL;
      break;
    case "production":
      rpc_url = process.env.SERVER_SOLID_APP_PROD_RPC_URL_1;
      break;
    default:
      rpc_url = process.env.SERVER_SOLID_APP_PROD_RPC_URL_1;
      break;
  }
  return {
    rpc_url: rpc_url as string,
    fallback: process.env.SERVER_SOLID_APP_PROD_RPC_URL_2 as string,
  };
};

export default fetchRpcUrl;
