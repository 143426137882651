import { Layout } from "~/Layout/Layout";
import { createRouteData } from "solid-start";
import { fetchPool } from "~/utils/fetchPool";
import IDsTable from "~/components/IDsTable/IDsTable";

export function routeData() {
  const id_data = createRouteData(async () => {
    const pool = await fetchPool();
    const client = await pool.connect();
    const ids = await client.query("SELECT * FROM ids");
    const offers = await client.query("SELECT * FROM idoffers");
    const times = await client.query("SELECT * FROM updatetimes");

    const { rows } = times;
    console.log(rows);

    client.release();
    console.log(ids);
    return {
      ids: ids.rows,
      offers: offers.rows,
      blockheight: rows[0].blockheight,
    };
  });
  return { id_data };
}

export default function Market() {
  return (
    <Layout>
      <div class="relative  w-full pagefooter-background p-10 lg:p-20 pb-20">
        <div class="relative flex flex-col space-y-6">
          <div class="absolute h-[75px] w-[75px] lg:h-[150px] lg:w-[150px] -top-0 -left-5 lg:-top-6 lg:-left-2">
            <img src="/blue_accent.png" class="object-cover" />
          </div>
          <div class="lg:px-10 relative text-white text-xl lg:text-4xl">
            Market
          </div>
          <div class="text-white relative text-[16px] lg:text-[24px] font-light lg:px-10 lg:max-w-[65%]">
            Welcome to the Verus On-Chain Marketplace. Trade IDs, tokens, NFTs,
            and more, directly on chain, without any trusted third-parties or
            middlemen. Explore current listings and offers below:{" "}
          </div>
        </div>
      </div>
      <div class="relative -top-10">
        <IDsTable />
      </div>
    </Layout>
  );
}
