import fetchCurrencies from "~/utils/data-utils/fetchCurrencies";
import { createResource, } from "solid-js";
import fetchRpcUrl from "~/utils/fetchRpcUrl";
import fetchOffers from "~/utils/data-utils/fetchOffers";
import { useRouteData } from "solid-start";
import { For } from "solid-js";


// SubID registration page:
//     -needs dropdown currency selection
//         -should autofill our referral id based on their selection
//     -when they click “Submit Response” during step 3, start running getblockcount
//         -show loading sequence “waiting for confirmation” underneath step 3 until result = previous result +1
//         -then show step 4


const { rpc_url, fallback } = fetchRpcUrl();

const referralIds = {}

export function routeData() {
    const [currencies] = createResource(async () => {
        try {
            return await fetchCurrencies(rpc_url);
        } catch (e: any) {
            if (fallback) {
                try {
                    return await fetchCurrencies(fallback);
                } catch (e: any) {
                    return e.message;
                }
            }
            return e.message;
        }
    });
    return { currencies };
}

export default function Home() {

    const { currencies } = useRouteData<typeof routeData>();

    return (
        <main>
            <form>
                <input type="text" placeholder="What name do you want to register?"></input>
                <input type="text" placeholder="What Verus address would you like to set as your primary address?"></input>
                <input type="text" placeholder="Do you have a referral ID? (If no, leave blank.)"></input>
            </form>
        </main>
    );
}
