import TableHeader from "./TableHeader";
import { For } from "solid-js";
import { fetchReserveCurrencyDetails } from "../../archive/EconomyTable/utils/fetchCurrencyDetails";
import { getRating } from "../../archive/EconomyTable/utils/getRating";
import { displayUSDPrice } from "../../archive/EconomyTable/utils/displayUsdPrice";
import { renderReserves } from "../../archive/EconomyTable/utils/renderReserves";
import { getCurrencyType } from "../../archive/EconomyTable/utils/getCurrencyType";
import { Tooltip } from "../Tooltip/Tooltip";
import { VRSC_CURRENCY_ID } from "./Table";
import { TableProps } from "./types/table.types";
import { useRouteData } from "solid-start";
import { routeData } from "~/routes/currencies";

export const DesktopTable = (props: TableProps) => {
  const { currency_data } = useRouteData<typeof routeData>();
  const {
    type,
    currencies,
    usd_price,
    handleEstimateCurrency,
    handleShowCurrencyModal,
    headerValues,
  } = props;
  return (
    <div class="w-full relative bg-slate-200/70 p-5 rounded-lg rounded-tr-none rounded-tl-none w-full drop-shadow-lg  border-4 border-[#22847B] mb-14 overflow-x-scroll ">
      <div class="absolute h-full w-full top-0 left-0 bg-gradient-to-b from-[rgba(119,149,161,0.75)] to-[rgba(119,149,161,0.5)] -z-1 rounded-lg rounded-tr-none rounded-tl-none" />
      <table class="relative table-auto z-10 pb-0 w-full">
        <TableHeader headings={headerValues} />
        <tbody class="space-y-5 [&>*:not(:last-child)]:border-b-2 [&>*:not(:last-child)]:border-slate-700">
          <For each={currencies}>
            {(currency) => {
              const reserves = fetchReserveCurrencyDetails(
                currency.reservecurrencies,
                currencies
              );

              const vrscReserves = reserves.find(
                (r) => r.currencyid === VRSC_CURRENCY_ID
              );

              const { rating, description } = getRating(
                currency.options,
                currency.proof,
                vrscReserves ? vrscReserves.weight : 0,
                reserves.length > 1
              );

              return (
                <tr class="text-sm hover:bg-slate-300">
                  <td class="p-4 align-left text-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B]">
                    {currency.name}
                  </td>
                  <td class="p-4 align-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] px-5 pr-10">
                    {reserves.length > 1 ? (
                      <span class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 text-[#114853] italic">
                        Not Applicable
                      </span>
                    ) : (
                      displayUSDPrice(
                        usd_price,
                        currency,
                        currency.reservecurrencies,
                        currency_data()?.bridge_currencies
                      )
                    )}
                  </td>
                  {type !== 'bridged' && <td class="p-4 align-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] px-5 pr-10">
                    {renderReserves(currency, reserves, vrscReserves)}
                  </td>}
                  {type !== "verus" && (
                    <td class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 text-[#1D847B] hover:text-[#1D847B] hover:underline cursor-pointer">
                      {currency.address ? (
                        <a
                          target="_blank"
                          href={`https://etherscan.io/address/${currency.address}`}
                        >
                          Contract {currency.address.substring(0, 3)}...
                          {currency.address.substring(
                            currency.address.length - 3,
                            currency.address.length
                          )}{" "}
                          etherscan
                        </a>
                      ) : (
                        <span class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 text-[#114853] italic">
                          Not Applicable
                        </span>
                      )}
                    </td>
                  )}
                  {type !== "bridged" && (
                    <td class="p-4 align-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] px-5 pr-10 text-left py-5 px-5">
                      {getCurrencyType(currency.id, reserves, currency.proof)}
                    </td>
                  )}
                  <td class="p-4 align-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] px-5 pr-10 text-left py-5 px-5">
                    <Tooltip text={description as any} placement="right">
                      {currency.id === VRSC_CURRENCY_ID ? (
                        <img src="/vrsc_logo.png" height="20px" width="20px" />
                      ) : (
                        `${rating}`
                      )}
                    </Tooltip>
                  </td>

                  <td class="text-left py-5 px-5 flex flex-col gap-y-4">
                    <button
                      onClick={() => handleEstimateCurrency(currency)}
                      class="bg-[rgba(34,132,123,1)] hover:bg-[rgba(34,132,123,0.75)] text-white font-bold py-2 px-4 rounded text-xs  whitespace-nowrap"
                    >
                      Estimate Conversion
                    </button>
                    <button
                      onClick={() => handleShowCurrencyModal(currency)}
                      class="bg-[rgba(34,132,123,1)] hover:bg-[rgba(34,132,123,0.75)] text-white font-bold py-2 px-4 rounded text-xs  whitespace-nowrap"
                    >
                      Show Details
                    </button>
                  </td>
                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
