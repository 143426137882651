import { For, createMemo, createSignal, onCleanup, onMount } from "solid-js";
import { isServer } from "solid-js/web";

type TableHeaderProps = {
    headings: string[];
    mobileHeadings: string[]
}
export default function BaseTableHeader(props: TableHeaderProps) {
    const [viewportWidth, setViewportWidth] = createSignal(0);
    const updateWidth = () => setViewportWidth(window.innerWidth);
    onMount(() => {
        // Function to update the viewport width
        // Set up an event listener for window resize to update the viewport width
        if (!isServer) {
            if (window) window.addEventListener('resize', updateWidth);
            updateWidth()
        }
    })


    // Clean up the event listener when the component is unmounted
    onCleanup(() => {
        if (!isServer) {
            if (window) window.removeEventListener('resize', updateWidth)
        }
    });
    return (
        <thead class="pb-2.5 w-full relative mb-2.5 mt-4 before:absolute before:w-full before:h-[1px] before:left-0 before:bottom-0 before:bg-gradient-to-r before:from-[#08F0F2] before:to-[#050D6777]">
            <tr class="space-x-5 w-full flex justify-between items-start">
                <For each={props.headings}>
                    {(heading) => (
                        <th class={`text-left text-base lg:text-xl text-white w-[33%]`}>{heading}</th>
                    )}
                </For>
            </tr>
        </thead>
    );
}
