import { createEffect, createSignal } from "solid-js";
import { useRouteData } from "solid-start";
import { routeData } from "~/routes/currencies";
import { ConversionModal } from "../ConversionModal/ConversionModal";
import CurrencyModal from "../CurrencyModal/CurrencyModal";
export const VRSC_CURRENCY_ID = "i5w5MuNik5NtLcYmNzcvaoixooEebB6MGV";
import { buildTable } from "./utils/buildTable";
import { onMount } from "solid-js";

export default function Table() {
  const { currency_data } = useRouteData<typeof routeData>();

  function checkIsMobile() {
    return window.innerWidth < 1075;
  }

  const [currencyForConversionModal, setCurrencyForConversionModal] =
    createSignal();
  const [currencyForCurrencyModal, setCurrencyForCurrencyModal] =
    createSignal();
  const [showModal, setShowModal] = createSignal<boolean>(false);
  const [showCurrencyModal, setShowCurrencyModal] =
    createSignal<boolean>(false);
  const [selectedTab, setSelectedTab] = createSignal<string>("all");
  const [isMobile, setIsMobile] = createSignal(false);

  createEffect(() => {
    const body = document.getElementsByTagName("body");
    if (body) {
      if (showModal() || showCurrencyModal()) {
        window.scrollTo(0, 0);
        body[0].style.overflow = "hidden";
      } else {
        body[0].style.overflow = "auto";
      }
    }
  });

  const handleEstmateCurrency = (currency: any) => {
    if (showModal()) setShowModal(false);
    setCurrencyForConversionModal(currency);
    setShowModal(true);
  };

  const handleShowCurrencyModal = (currency: any) => {
    if (showCurrencyModal()) setShowCurrencyModal(false);
    setCurrencyForCurrencyModal(currency);
    setShowCurrencyModal(true);
  };

  onMount(() => {
    // Initial check on mount
    setIsMobile(checkIsMobile());
    // Setup event listener for resizing
    const updateSize = () => {
      setIsMobile(checkIsMobile());
    };
    window.addEventListener("resize", updateSize);
    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateSize);
    };
  });

  return (
    <>
      <div class="flex flex-col w-full items-center">
        <div class="relative flex justify-center space-x-4 ml-10 mb-4">
          <button
            onClick={() => setSelectedTab("all")}
            class={` relative group inline-flex items-center justify-center rounded-md text-base overflow-hidden font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 text-[#1D847B] `}
          >
            {selectedTab() === "all" && (
              <div class="absolute h-full w-full top-0 left-0 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground  bg-gradient-to-r from-[#1D847B] to-[#114853] focus:ring-2 focus:ring-offset-2 focus:ring-[#1D847B] " />
            )}
            <span
              class={`relative z-[0] ${selectedTab() === "all" && "!text-white"
                }`}
            >
              All
            </span>
          </button>
          <button
            onClick={() => setSelectedTab("verus")}
          >
            {selectedTab() === "verus" && (
              <div class="absolute h-full w-full top-0 left-0 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground  bg-gradient-to-r from-[#1D847B] to-[#114853] focus:ring-2 focus:ring-offset-2 focus:ring-[#1D847B]" />
            )}
            <span
              class={`${selectedTab() === "verus" && "!text-white"
                } relative z-[0]`}
            >
              Verus Currencies
            </span>
          </button>
          <button
            onClick={() => setSelectedTab("bridged")}
            class="relative overflow-hidden inline-flex items-center justify-center rounded-md text-base font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 hover:bg-accent hover:text-accent-foreground h-10 px-4 py-2 text-[#1D847B]"
          >
            {selectedTab() === "bridged" && (
              <div class="absolute h-full w-full top-0 left-0 transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 bg-accent text-accent-foreground  bg-gradient-to-r from-[#1D847B] to-[#114853] focus:ring-2 focus:ring-offset-2 focus:ring-[#1D847B] " />
            )}
            <span
              class={`${selectedTab() === "bridged" && "!text-white"
                } relative z-[0]`}
            >
              Bridged Currencies
            </span>
          </button>
        </div>
        <div
          class={`w-full tab-content ${selectedTab() === "all" ? "inline" : "hidden"
            }`}
        >
          {buildTable(
            "all",
            isMobile(),
            currency_data()?.currencies,
            currency_data()?.usd_price as string,
            handleEstmateCurrency,
            handleShowCurrencyModal
          )}
        </div>
        <div
          class={`w-full tab-content  ${selectedTab() === "verus" ? "inline" : "hidden"
            }`}
        >
          {buildTable(
            "verus",
            isMobile(),
            currency_data()?.currencies?.filter((c) => c.proof === 1),
            currency_data()?.usd_price as string,
            handleEstmateCurrency,
            handleShowCurrencyModal
          )}
        </div>
        <div
          class={`w-full tab-content ${selectedTab() === "bridged" ? "inline" : "hidden"
            }`}
        >
          {buildTable(
            "bridged",
            isMobile(),
            currency_data()?.currencies?.filter((c) => c.proof === 3),
            currency_data()?.usd_price as string,
            handleEstmateCurrency,
            handleShowCurrencyModal
          )}
        </div>
      </div>

      {showModal() && (
        <ConversionModal
          currency={currencyForConversionModal() as string}
          closeModal={() => setShowModal(false)}
        />
      )}
      {showCurrencyModal() && (
        <CurrencyModal
          currency={currencyForCurrencyModal}
          closeModal={() => setShowCurrencyModal(false)}
        />
      )}
    </>
  );
}
