import { For, JSX, Show, createMemo, createSignal, onMount, onCleanup } from "solid-js";
import TableHeader from "./BaseTableHeader";
import Paginator from "../Paginator/Paginator";
import { PageStore } from "~/stores/page.store";
import { sortStore, pageStore, searchStore } from "~/stores";
import { SortDirections } from "~/stores/sort.store";
import { isServer } from "solid-js/web";

interface TableProps {
    tableTabs: Array<Record<string, JSX.Element>>;
    tableHeaders: string[],
    mobileTableHeaders: string[],
    tableRows: Record<string, Array<{ data: any; markup: Array<JSX.Element>; mobileMarkup: Array<JSX.Element> }>>;
    sortFields?: Record<string, string[]>
    searchParams?: Record<string, string[]> | any;
    perPage?: number;
    accessoryFunction?: Record<string, () => void>
}


const getNewDirection = (direction: SortDirections): SortDirections => {
    switch (direction) {
        case 'ASC':
            return 'DSC'
        case "DSC":
            return "ASC"
    }

}
const BaseTable = (props: TableProps) => {
    const firstTab = props.tableTabs[0];
    const firstKey = Object.keys(firstTab)[0];
    const [activeTab, setActiveTab] = createSignal<string>();
    const [fuzzySearch, setFuzzySearch] = createSignal<boolean>();
    const [isMobile, setIsMobile] = createSignal<boolean>()

    const { store: page_store, setInitialValues, } = pageStore;
    const { store: sort_store, setInitialDirection, updateDirection } = sortStore;
    const { store: search_store, initSearch, searchIds, setToggleFuzzySearch } = searchStore;

    const updateWidth = () => {
        if (window && window.innerWidth > 729) {
            setIsMobile(false)
            return;
        }
        setIsMobile(true)
    }

    onMount(() => {
        setActiveTab(firstKey)
        const tabs = Object.keys(props.tableRows);
        for (let tab of tabs) {
            setInitialValues(tab, props.tableRows[tab], props.perPage || 100)
            if (props.sortFields) setInitialDirection(tab, props.sortFields[tab][0], "ASC")
            if (props.searchParams) {
                setFuzzySearch(false)
                initSearch(tab, false, props.tableRows[tab], props.searchParams[tab])
            }
        }
        if (!isServer) {
            if (window) window.addEventListener('resize', updateWidth);
            updateWidth()
        }
    })

    onCleanup(() => {
        if (!isServer) {
            if (window) window.removeEventListener('resize', updateWidth)
        }
    })

    const items = createMemo(() => {
        let res: Record<string, any> = {}
        if (!activeTab()) return props.tableRows

        const tabs = Object.keys(props.tableRows)
        for (let tab of tabs) {
            let data
            const search = search_store.map.get(tab)

            const items = ((search?.result) && (search?.result.length > 0)) ? search.result : props.tableRows[tab]

            if (props.sortFields) {
                const sorting = sort_store.map.get(tab) as any;
                const { current_selection } = sorting;
                const [field, selection] = current_selection.split('_');
                const sortedData = items.sort((a, b) => {
                    if (selection === "ASC") {
                        return (a.data[field] as number) - (b.data[field] as number)
                    } else {
                        return (b.data[field] as number) - (a.data[field] as number)
                    }
                })

                data = sortedData;
            } else {
                data = props.tableRows[tab]
            }

            const { startingIndex, endingIndex } = page_store.map.get(tab) as PageStore
            const paginated = data.slice(startingIndex, endingIndex)
            res[tab] = paginated
        }
        return res
    });

    const sortState = createMemo(() => {
        if (!activeTab()) return
        if (!props.sortFields) return;
        const sorting = sort_store.map.get(activeTab() as string) as any;
        const { current_selection } = sorting;
        const [field, selection] = current_selection.split('_');
        return {
            field,
            selection
        }
    })

    const handleUpdateDirection = (clickedField: string) => {
        const state = sortState()
        const { selection } = state as any;

        const newdir = getNewDirection(selection as SortDirections);
        updateDirection(activeTab() as string, clickedField, newdir)
    }

    console.log(props.tableTabs)

    return (
        <div class="w-full relative">
            <div class="w-full flex flex-wrap items-start justify-between mb-5 gap-y-10 ">
                <div class="flex flex-wrap gap-6 ">
                    <Show when={props.tableTabs.length > 1} fallback={(<></>)}>
                        <For each={props.tableTabs}>
                            {(tab) => {
                                const key = Object.keys(tab)[0];
                                return (
                                    <div class={`${activeTab() === key ? "text-link-color" : "text-white"}`} onClick={() => setActiveTab(key)}>{tab[key]}</div>
                                )
                            }}
                        </For>
                    </Show>

                </div>

                {/* filtering and sorting */}
                {
                    props.sortFields && activeTab() && (
                        <div class="flex gap-x-10 text-white mr-10 text-base">
                            <div class="whitespace-nowrap">
                                Sort By:
                            </div>
                            <For each={props.sortFields[activeTab() as string]}>
                                {(field) => {

                                    return (
                                        <div onClick={() => handleUpdateDirection(field)} class={`flex space-x-2 capitalize ${field === sortState()?.field ? 'text-link-color' : ''}`}>
                                            <span>
                                                {field}
                                            </span>
                                            <div class={`${sortState()?.selection == "ASC" && field === sortState()?.field ? "rotate-180" : ""} h-[25px] w-[25px]`}>
                                                <img src='/arrowDown.svg' height={50} width='auto' />
                                            </div>
                                        </div>
                                    )
                                }}
                            </For>
                        </div>
                    )
                }
                {
                    props.searchParams && (
                        <div class="mr-14 -translate-y-3.5 flex flex-row-reverse items-baseline gap-x-5">
                            <input onChange={(e) => {
                                searchIds(activeTab() as string, e.target.value)
                            }} placeholder='Search' class="peer h-full w-full min-w-[150px] border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-white outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-link-color focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                            <div class="flex text-white items-center gap-x-3">
                                <label class="whitespace-nowrap">Fuzzy</label>
                                <input type='checkbox' checked={fuzzySearch()} onChange={() => setToggleFuzzySearch(activeTab() as string)} />
                            </div>
                        </div>
                    )
                }
                {
                    props.accessoryFunction && (
                        <For each={Object.keys(props.accessoryFunction)}>
                            {
                                (label: string) => {
                                    const func = props.accessoryFunction ? props.accessoryFunction[label] : () => { }
                                    return (
                                        <button class="outline outline-[1px] outline-link-color text-link-color p-1 hover:outline-link-color hover:bg-white hover:text-black hover:cursor-pointer" onClick={func}>
                                            {label}
                                        </button>
                                    )
                                }
                            }
                        </For>
                    )
                }
            </div>
            <div class="w-full relative  mt-10">
                <For each={props.tableTabs}>
                    {
                        (tab) => {
                            const key = Object.keys(tab)[0];
                            return (
                                <table class={`${activeTab() === key ? "inline" : "hidden"} w-full flex flex-col justify-between text-white text-base lg:text-2xl font-light leading-tight`}>
                                    <TableHeader headings={props.tableHeaders} mobileHeadings={props.mobileTableHeaders} />

                                    <For each={items()[key]}>
                                        {
                                            (rowItem) => {
                                                console.log(rowItem)
                                                return (
                                                    <tr class="w-full flex justify-between items-center pb-5 mb-5  relative before:absolute before:w-full before:h-[1px] before:left-0 before:bottom-0 before:bg-gradient-to-r before:from-[#08F0F2] before:to-[#050D6777]">
                                                        <For each={rowItem.markup}>
                                                            {
                                                                (item) => {
                                                                    return <td class="w-[33%]">{item}</td>
                                                                }
                                                            }
                                                        </For>
                                                    </tr>
                                                )
                                            }
                                        }
                                    </For>

                                </table>
                            )
                        }
                    }
                </For>
            </div>
            <div>
                {search_store.map.get(activeTab() as string)?.result?.length === 0 && (
                    <Paginator tab={activeTab() as string} />
                )}
            </div>
        </div>
    )
}

export default BaseTable;
