import { createEffect, createMemo, createSignal } from "solid-js";
import pageStore, { PageStore } from "~/stores/page.store";
import { PageItem } from "./PageItem";

interface PaginatorProps {
    onPageChange?: (page: number) => void;  // Callback function when the page changes
    tab: string;
}


function Paginator(props: PaginatorProps) {

    const { store, setCurrentPage } = pageStore;


    const pageData = createMemo(() => {
        const data = store.map.get(props.tab) as PageStore;
        if (!data) return;
        return {
            ...data
        }
    })


    const goToPage = (page: number) => {
        setCurrentPage(props.tab, page)
    };

    const renderSection = () => {
        const pgDat = pageData() as PageStore;
        if (!pgDat) return <div>...loading</div>
        const pages = [];

        if (pgDat.total_pages as number < 4) {
            for (let i = 1; i <= pgDat.total_pages; i++) {
                pages.push(
                    <PageItem
                        index={i}
                        page={pgDat.current_page}
                        tab={props.tab}
                    />
                )
            }
        } else {
            for (let i = 1; i <= pgDat.total_pages; i++) {
                if (pgDat.total_pages === 4) {
                    if (pgDat.current_page === 1 && i < pgDat.total_pages) {
                        pages.push(
                            <PageItem
                                index={i}
                                page={pgDat.current_page}
                                tab={props.tab}
                            />
                        );
                    } else {
                        pages.push(
                            <PageItem
                                index={i}
                                page={pgDat.current_page}
                                tab={props.tab}
                            />
                        );
                    }
                }
                if (pgDat.current_page < 4) {
                    if (i < 4) {
                        pages.push(
                            <PageItem
                                index={i}
                                page={pgDat.current_page}
                                tab={props.tab}
                            />
                        )
                    }
                    if (i === 5) {
                        pages.push(
                            <div>...</div>
                        )
                    }
                    if (i === pgDat.total_pages) {
                        pages.push(
                            <PageItem
                                index={i}
                                page={pgDat.current_page}
                                tab={props.tab}
                            />
                        )
                    }
                } else {
                    if (pgDat.current_page >= pgDat.total_pages - 2) {
                        if (i === 1 || i >= pgDat.total_pages - 2) {
                            pages.push(
                                <PageItem
                                    index={i}
                                    page={pgDat.current_page}
                                    tab={props.tab}
                                />
                            )
                        }
                        if (i == 2) {
                            pages.push(
                                <div>...</div>
                            )
                        }
                    } else {
                        const section = Math.floor((pgDat.total_pages - pgDat.current_page) / 2);
                        const endPos = pgDat.total_pages - section * 2;
                        if (i === 1 || i === pgDat.total_pages || (i >= endPos - 1 && i <= endPos)) {
                            pages.push(
                                <PageItem
                                    index={i}
                                    page={pgDat.current_page}
                                    tab={props.tab}
                                />
                            )
                        }
                        if (i === 2 || i === endPos + 1) {
                            pages.push(
                                <div>...</div>
                            )
                        }
                    }
                }
            }
        }
        return pages;
    }
    return (
        <div class="flex text-white w-full items-center justify-center my-16 text-sm lg:text-lg">
            <button onClick={() => goToPage(pageData()?.current_page as number - 1)} disabled={pageData()?.current_page === 1} class="flex mr-4 items-center justify-center -space-x-1 hover:cursor-pointer hover:-translate-x-2 transition-all ease-in-out duration-150 px-3 lg:px-0">
                <div class="scale-[0.2] lg:scale-[0.8] hidden lg:inline">
                    <img src='/left-arrow.svg' alt='left arrow' />
                </div>

                <span>Prev</span>
            </button>
            {renderSection()}
            {/* <button class={`font-semibold text-2xl px-5 py-3.5 mx-3 inline lg:hidden rounded-lg  bg-[rgba(34,132,123,0.75)]`}>
                {pageData()?.current_page}
            </button>
            {pageData()?.total_pages && Array.from({ length: pageData()?.total_pages as number }).map((_, index) => (
                <button
                    class={`font-semibold text-2xl px-5 py-3.5 mx-3 hidden lg:inline  rounded-lg ${true ? "bg-[rgba(34,132,123,0.75)]" : ""}`}
                    onClick={() => goToPage(index + 1)}
                >
                    <span>
                        {index + 1}
                    </span>

                </button>
            ))} */}
            <button onClick={() => goToPage(pageData()?.current_page as number + 1)} disabled={pageData()?.current_page === pageData()?.total_pages} class="flex items-center justify-center -space-x-1 hover:cursor-pointer hover:translate-x-2 transition-all ease-in-out duration-150 px-3 lg:px-0 ml-4">
                <span>
                    Next
                </span>
                <div class="scale-[0.2] lg:scale-[0.8] rotate-180 hidden lg:inline">
                    <img src='/left-arrow.svg' alt='left arrow' />
                </div>

            </button>
        </div>
    );
}

export default Paginator;
