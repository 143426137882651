import { createResource } from "solid-js";
import Table from "~/components/CurrencyTable/Table";
import { Layout } from "~/Layout/Layout";
import { fetchPool } from "~/utils/fetchPool";
import { VRSC_CURRENCY_ID } from "~/components/CurrencyTable/Table";

export function routeData() {
  const [currency_data] = createResource(async () => {
    try {

      const pool = await fetchPool();

      const client = await pool.connect();
      const currencies = await client.query('SELECT * FROM currencies');
      const updateTime = await client.query('SELECT * FROM updatetimes');
      const api = await client.query('SELECT * FROM api');

      const p = api.rows.find((x) => x.call_name === 'vrscusd');
      const sup = api.rows.find((x) => x.call_name === 'vrscsupply')

      const bridge_currencies = api.rows.filter((r) => r.call_name !== 'vrscusd' && r.call_name !== 'vrscsupply');

      const vrsc_res: Record<string, number> = {};
      const vrsc = currencies.rows.find(c => c.currency_iaddr === VRSC_CURRENCY_ID);
      const others = currencies.rows.filter(c => c.currency_iaddr !== VRSC_CURRENCY_ID);

      others?.map((c) => {
        if (!c.reservecurrencies) {
          vrsc_res[c.currency_iaddr] = 0;
          return
        }
        const res = c.reservecurrencies?.map((x: any) => {
          if (x.currencyid === VRSC_CURRENCY_ID) {
            return x.reserves
          }
          return 0
        })
        vrsc_res[c.currency_iaddr] = res[0];
        return
      })

      const sorted_others = others.sort((a, b) => {
        return vrsc_res[b.currency_iaddr] - vrsc_res[a.currency_iaddr]
      })

      const c = [vrsc, ...sorted_others]

      client.release();

      return {
        usd_price: `${p.value}`,
        vrsc_supply: `${sup.value}`,
        bridge_currencies,
        currencies: c,
        updateTime: updateTime.rows
      }
    } catch {
      throw new Error("There was an issue fetching usd price");
    }
  })

  return { currency_data };
}

export default function Currencies() {
  return (
    <Layout>
      <div class="flex flex-wrap w-full items-center justify-center mt-5 pt-10 overflow-x-auto container">
        <Table />
      </div>
    </Layout>
  );
}
