import { createSignal, createMemo, JSX } from "solid-js";

interface TooltipProps {
  text: string | Element;
  children: JSX.Element;
  placement: "top" | "right" | "bottom" | "left";
}

export function Tooltip(props: TooltipProps) {
  const { text, placement } = props;

  const [show, setShow] = createSignal<boolean>(false);

  const positionStyle = createMemo(() => {
    switch (placement) {
      case "top":
        return "";
      case "bottom":
        return "";
      case "left":
        return "-left-[130%]";
      case "right":
        return "-right-[130%]";
    }
  });

  return (
    <div
      class="hover:cursor-pointer relative h-fit w-fit"
      onMouseEnter={() => setShow(true)}
      onMouseLeave={() => setShow(false)}
    >
      {props.children}
      {text && (
        <div
          class={`${!show() && "hidden"
            } z-10 absolute h-fit w-fit -translate-y-1/2 top-[50%] pagefooter-background flex items-center justify-center p-3 rounded-lg m-auto  ${positionStyle()}`}
        >
          <div class="text-white text-sm w-fit text-cente tracking-wider">{text}</div>
        </div>
      )}
    </div>
  );
}
