export const currency_notes: Record<string, string> = {
  vrsc: `The native currency of the Verus blockchain. <br />
  Website: https://verus.io/ <br/>
  Docs: https://docs.verus.io/ <br/>
  Vision Paper: https://verus.io/papers`,

  pbaaslaunch: `This currency was used to create commemorative .PBaaSLaunch subIDs, celebrating the long awaited launch of Verus’ PBaaS protocol. Anyone who requested one in the Verus discord received one free .PBaaSLaunch subID of their choosing. The PBaaSLaunch currency is unobtainable and the supply is final.`,
  bitcoins: `A 21 million supply, non-reserve currency. The entire supply was pre-allocated to 4 Verus IDs. They received the following amounts: <br />
  cybermoney@ - 6,666,667 bitcoins <br />
  🟠 @ - 6,666,667 bitcoins <br />
  🎢 @ - 6,666,666 bitcoins <br />
  Verus Coin Foundation@ - 1,000,000 bitcoins <br />
  Though the currency can be used to mint subIDs, none have been minted.
  `,
  racecondition: `"'RaceCondition' is a highly volatile, minimal reserve ratio (5% reserves) coin that turned 10 VRSC into a market cap of 200 VRSC, possibly waiting for a big holder to dump on whoever joins. 5% reserve ratio is the minimum and results in the most volatile coin that can be made. The fact that the launch was very short and the entire discount went mostly or all to one person means they almost certainly have the ability to dump, and I'd probably steer clear of it unless you want to buy IDs, which are quite cheap on RaceCondition right now." - Mike T`,
  whales: `“‘whales’ seems actually like an interesting experiment & a non-marketed meme-ish coin with an interesting effect of its launch on ID pricing. IDs are 20 in the coin and also have 3 levels of referral, meaning with a referral, 16 whales, and the price can be as low as 4 whales + 0.02 VRSC import fee. If there is a lot of participation, whales IDs will be very expensive at launch. If not, they will be inexpensive. In any case, the starting supply doesn't buy too many IDs. As a 100% currency, it will earn fees from IDs, and they may actually be high relative to the supply, which could make holders earn decent fees. If the ID prices end up too high, it may price itself out of being too interesting, but I'm curious to see how it plays out.” -Mike T`,
};
