import generatePayload from "../generatePayload";
import axios from "axios";

const fetchCurrencies = async (rpc_url: string) => {
  const requestBody: Record<string, any> = {
    ...generatePayload("listcurrencies", "verusidsdotcom", []),
  };

  const { data: vrsc_currencies_data } = await axios.post(
    rpc_url,
    requestBody,
    {
      headers: {
        ["Content-Type"]: "application/json",
      },
    }
  );

  const { result } = vrsc_currencies_data;

  const data = result.map((currency: any) => {
    return {
      id: currency.currencydefinition.currencyid,
      name: currency.currencydefinition.name,
      options: currency.currencydefinition.options,
      proofprotocol: currency.currencydefinition,
      bestcurrencystate: currency.bestcurrencystate,
      reservecurrencies: currency.bestcurrencystate.reservecurrencies,
    };
  });

  return data;
};

export default fetchCurrencies;
