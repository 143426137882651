import { createStore } from "solid-js/store";
import Fuse from "fuse.js";

type SearchMap = {
  value: string;
  ids: any[] | undefined;
  markup: any[];
  fuse: Fuse<any>;
  result: any[] | undefined;
  searchParams: string[];
  fuzzySearch: boolean;
};

type SearchStore = {
  map: Map<string, SearchMap>;
};

const [store, setStore] = createStore<SearchStore>({
  map: new Map(),
});

const initSearch = (
  key: string,
  fuzzySearch: boolean,
  ids: any[],
  searchParams: string[]
) => {
  if (!store.map.has(key)) {
    const newMap = new Map(store.map);

    const options: Record<string, any> = {
      keys: searchParams,
      includeScore: true,
      shouldSort: true,
      isCaseSensitive: false,
    };
    if (!fuzzySearch) {
      options.threshold = 0;
    }

    console.log(ids)
    const fuse = new Fuse(
      ids.map((d) => d.data),
      options
    );

    const markup = ids.map((id) => id.markup);

    newMap.set(key, {
      value: "",
      ids: ids,
      markup,
      result: [],
      fuzzySearch,
      fuse,
      searchParams,
    });
    setStore({
      map: newMap,
    });
  }
};

const searchIds = (key: string, value: string) => {
  if (store.map.has(key)) {
    const search_store = store.map.get(key) as SearchMap;
    const { ids, fuse, fuzzySearch, searchParams, markup } = search_store;

    const res = fuse.search(value);
    const elements = res.map((item) => ({
        item: item.item,
        refIndex: item.refIndex,
        score:item.score
      }))
      .map(({ item, refIndex, score}) => {
        const markup = ids?.find((id) => id.data.name === item.name);
        return {
          data: item,
          markup: markup.markup,
          score
        };
      });
    const newMap = new Map(store.map);
    elements.sort((a: any,b: any) => {
      return parseFloat(a.score) - parseFloat(b.score)
    })
    console.log(elements)
    newMap.set(key, {
      value,
      ids,
      result: elements.reverse(), 
      fuzzySearch,
      markup,
      fuse,
      searchParams,
    });

    setStore({
      map: newMap,
    });
  }
};

const setToggleFuzzySearch = (key: string) => {
  if (store.map.has(key)) {
    const search_store = store.map.get(key) as SearchMap;
    const { value, fuzzySearch, result, ids, searchParams, markup } =
      search_store;

    const newMap = new Map(store.map);

    const newfuzz = !fuzzySearch;
    const options: Record<string, any> = {
      keys: searchParams,
      includeScore: true,
      shouldSort: true,
      isCaseSensitive: false,
    };

    if (!newfuzz) {
      options.threshold = 0;
    }
    const fuse = new Fuse(ids?.map((d) => d.data) || [], options);

    newMap.set(key, {
      value,
      ids,
      result,
      markup,
      fuzzySearch: newfuzz,
      fuse,
      searchParams,
    });

    setStore({
      map: newMap,
    });
  }
};

export default { store, searchIds, setToggleFuzzySearch, initSearch };
