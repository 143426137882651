import { VRSC_CURRENCY_ID } from "../CurrencyTable/Table"
import { createMemo, createSignal } from "solid-js"


interface CurrencyTypeProps {
    currencyId: string,
    reserves: Array<any>,
    proof: number,
    currency: any,
    vrscReserves: any

}
const getWeightColor = (reserves: any, vrscReserves: any) => {
    if (reserves.length == 1 && vrscReserves.weight < 0.1) {
        return "text-red-600";
    }
};

export const CurrencyType = (props: CurrencyTypeProps) => {

    const [hovered, setHovered] = createSignal<boolean>(false);

    const currencyType = createMemo(() => {
        if (props.currencyId === VRSC_CURRENCY_ID) return "";
        if (props.reserves?.length > 1 && props.proof != 3) return "Multi Currency Basket"
        if (props.proof === 3) return "Bridge"
        if (props.reserves.length === 1) return "Reserve Currency"
        return "Token"
    })
    return (
        <div class="relative">
            <div class="text-sm relative flex flex-col items-start justify-start" onMouseEnter={() => setHovered(true)} onMouseLeave={() => setHovered(false)} onTouchStart={() => setHovered(true)} onTouchEnd={() => setHovered(false)}>
                <span>
                    {currencyType()}
                </span>
                {props.reserves?.length > 0 && (<span>
                    (View Reserves)
                </span>)}
            </div>
            {hovered() && (props.reserves?.length > 0) && (
                <div class="flex items-center justify-center absolute top-6 left-0 bg-accent-darkgreen/90 p-5 rounded-[2px] z-20">
                    <div class="flex flex-col gap-3">
                        {props.reserves &&
                            props.reserves.map((r: any, index: number) => {
                                return (
                                    <span class={`${getWeightColor(props.reserves, props.vrscReserves)} text-sm text-white font-black`}>
                                        {r.reserves.toFixed(2)} {props.currency.rescurrlist[index]} (
                                        {(r.weight * 100).toFixed(2)}%)
                                    </span>
                                );
                            })}
                    </div>
                </div>

            )}

        </div>

    )
}