import { JSX, createSignal, onMount } from "solid-js";
import { onCleanup } from "solid-js";
import { isServer } from "solid-js/web";

interface ModalProps {
  content: JSX.Element;
  closeModal: Function;
  hideBackground?: boolean;
}

function Modal(props: ModalProps) {
  function handleKeyDown(event: any) {
    if (event.key === "Escape" || event.keyCode === 27) {
      props.closeModal()
    }
  }
  // Add the event listener when the component is mounted
  if (!isServer) window.addEventListener("keydown", handleKeyDown);

  onMount(() => {
    if (!isServer) document.body.style.overflow = 'hidden'

  })
  // Remove the event listener when the component is unmounted
  onCleanup(() => {
    if (!isServer) {
      window.removeEventListener("keydown", handleKeyDown);
      document.body.style.overflow = 'auto'
    }
  });

  return (
    <div class="fixed h-[110vh] w-screen top-0 left-0 z-[100] ">
      <div class="absolute h-full w-full bg-[rgba(255,225,245,0.45)] backdrop-blur  top-0 left-0" />
      <div class={`absolute h-fit w-fit max-w-[95vw] overflow-x-scroll z-50 left-1/2 top-1/2 -translate-x-[50%] -translate-y-[63%] flex items-center justify-center`}>
        <div>
          <button class="absolute z-[51] top-6 scale-[1.75] right-6 h-fit w-fit" onClick={() => props.closeModal()}>
            <img src="/close_icon.svg" class="" height="16" width="16" />
          </button>
        </div>
        {props.content}
      </div>
    </div>
  );
}

export default Modal;
