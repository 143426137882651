
import RegisterComponent from "~/components/Register/Register";
import { Layout } from "~/Layout/Layout";
import { createRouteData } from "solid-start";
import { fetchPool } from "~/utils/fetchPool";




export function routeData() {
    const referrals = createRouteData(async () => {
        const pool = await fetchPool();
        const client = await pool.connect();
        const referrals = await client.query('SELECT * FROM idreferrals');
        client.release();
        return referrals.rows
    })
    return { referrals }
}

export default function Register() {
    return (
        <Layout onlyNav>
            <div class="w-full flex items-center justify-center mt-20">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/AUUo1zapa7c?si=vUKXxmwpC6d__j9F" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <div class="flex flex-wrap w-full  m-auto items-center justify-center mt-20">
                <RegisterComponent />
            </div>
        </Layout>
    );
}
