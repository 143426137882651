export const getRating = (
  options: number,
  proofProtocol: number,
  weight: number,
  basketCurrency: boolean
) => {
  if ((options === 33 || options === 41) && proofProtocol !== 2 && weight === 1)
    return {
      rating: "🌟🌟🌟",
      description: "UpOnly!!",
    };
  if (!basketCurrency && weight < 0.1 && weight !== 0)
    return {
      rating: "💩",
      description: (
        <div class="flex flex-col items-center justify-center w-fit">
          <div>Currency is under the VRSC recommended reserve weight.</div>
          <img src='/racecondition.webp' class="h-[14rem] min-w-[25rem]" />
        </div>
      ),
    };
  if (basketCurrency && weight >= 0.1)
    return {
      rating: "⚠️",
      description: "",
    };
  if (proofProtocol === 2)
    return {
      rating: "🚩",
      description: "Token owner can mint an unlimited number of tokens!",
    };
  else
    return {
      rating: "Not Rated",
      description: "",
    };
};
