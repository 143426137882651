import pageStore from "~/stores/page.store"

interface PageItemProps {
    index: number
    page: number;
    tab: string
}
export const PageItem = (props: PageItemProps) => {
    const { setCurrentPage } = pageStore;
    const handleSetPage = (page: number) => {
        setCurrentPage(props.tab, page)
    }
    return (
        <button onClick={() => handleSetPage(props.index)}>
            <div class={`rounded py-2 px-4 text-xs lg:text-xl ${props.page === props.index ? 'bg-link-color' : ''}`}>
                {props.index}
            </div>
        </button>
    )
}