import TableHeader from "./TableHeader";
import { For } from "solid-js";
import { VRSC_CURRENCY_ID } from "./Table";
import { getRating } from "../../archive/EconomyTable/utils/getRating";
import { displayUSDPrice } from "../../archive/EconomyTable/utils/displayUsdPrice";
import { Tooltip } from "../Tooltip/Tooltip";
import { fetchReserveCurrencyDetails } from "../../archive/EconomyTable/utils/fetchCurrencyDetails";
import { useRouteData } from "solid-start";
import { routeData } from "~/routes/currencies";
import { TableProps } from "./types/table.types";

export const MobileTable = (props: TableProps) => {
  const { currency_data } = useRouteData<typeof routeData>();
  const { currencies, usd_price, handleEstimateCurrency, handleShowCurrencyModal, headerValues } = props;
  return (
    <div class="relative bg-slate-200/70 p-5 w-full  drop-shadow-lg ring-4 ring-[#22847B] mb-14 overflow-x-scroll mx-10 -left-10">
      <div class="absolute h-full w-full top-0 left-0 bg-gradient-to-b from-[rgba(119,149,161,0.75)] to-[rgba(119,149,161,0.5)] -z-1 " />
      <table class="relative table-auto w-full z-10 pb-0">
        <TableHeader headings={headerValues} />
        <tbody class="space-y-5 [&>*:not(:last-child)]:border-b-2 [&>*:not(:last-child)]:border-slate-700">
          <For each={currencies}>
            {(currency) => {
              const reserves = fetchReserveCurrencyDetails(
                currency.reservecurrencies,
                currencies
              );
              const vrscReserves = reserves.find(
                (r: any) => r.currencyid === VRSC_CURRENCY_ID
              );
              const { rating, description } = getRating(
                currency.options,
                currency.proof,
                vrscReserves ? vrscReserves.weight : 0,
                reserves.length > 1
              );
              return (
                <tr class="text-sm hover:bg-slate-300">
                  <td
                    class="p-4 align-left text-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] py-5 px-5"
                    onClick={() => handleShowCurrencyModal(currency)}
                  >
                    {currency.name}
                  </td>
                  <td
                    onClick={() => handleShowCurrencyModal(currency)}
                    class='p-4 align-left text-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B]'>
                    {reserves.length > 1
                      ? ""
                      : displayUSDPrice(
                        usd_price,
                        currency,
                        currency.reservecurrencies,
                        currency_data()?.bridge_currencies
                      )}
                  </td>
                  <td
                    onClick={() => handleShowCurrencyModal(currency)}
                    class="p-4 align-left text-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] py-5 px-5">
                    <Tooltip text={description as any} placement="right">
                      {currency.id === VRSC_CURRENCY_ID ? (
                        <img src="/vrsc_logo.png" height="20px" width="20px" />
                      ) : (
                        `${rating}`
                      )}
                    </Tooltip>
                  </td>
                  <td class="p-4 align-left text-left [&amp;:has([role=checkbox])]:pr-0 font-medium text-[#1D847B] py-5 px-5">
                    <button
                      onClick={() => handleEstimateCurrency(currency)}
                      class="bg-[rgba(34,132,123,1)] hover:bg-[rgba(34,132,123,0.75)] text-white font-bold py-2 px-4 rounded text-xs max-w-[3rem]"
                    >
                      <img
                        class="fill-white mr-10 rotate-90 hover:cursor-pointer hover:outline hover:outline-2 active:outline-1 hover:outline-white rounded-full transition-all ease-in-out duration-200"
                        src="reverse.svg"
                        alt="reverse"
                      />
                    </button>
                  </td>

                </tr>
              );
            }}
          </For>
        </tbody>
      </table>
    </div>
  );
};
