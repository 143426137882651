export const fetchReserveCurrencyDetails = (
  reserve: any[],
  currencies: any
) => {
  if (!reserve) return [];
  return reserve.map((r: any) => {
    const currs = currencies;
    const targetCurr = currs?.find((c: any) => c.currency_id === r.currencyid);
    return {
      ...r,
      ...targetCurr,
    };
  });
};
