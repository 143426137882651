import { VRSC_CURRENCY_ID } from "../../../components/CurrencyTable/Table";
function percentDifference(value1: number, value2: number) {
  // Calculate the absolute difference between the two values
  var difference = Math.abs(value1 - value2);

  // Calculate the average of the two values
  var average = (value1 + value2) / 2;

  // Calculate the percent difference
  var percentDiff = (difference / average) * 100;

  return `${!isNaN(percentDiff) ? percentDiff.toFixed(2) + " %" : ""}`;
}

export const displayUSDPrice = (
  usd_price: number,
  currency: any,
  reserve: any,
  bridge_currencies: any,
  onChainPrices: Record<string, number>
) => {
  if (currency.currency_iaddr === VRSC_CURRENCY_ID) return `$ ${usd_price}`;
  else if (reserve)
    return `$ ${(reserve[0].priceinreserve * usd_price).toFixed(1)}`;
  else if (
    bridge_currencies &&
    bridge_currencies.some((c: any) => c.call_name === currency.name)
  ) {
    const curr = bridge_currencies.find(
      (f: any) => f.call_name === currency.name
    );
    return `${
      onChainPrices[currency.name]
        ? `$${onChainPrices[currency.name].toFixed(3)} (On Chain) | `
        : ""
    } $${curr.value} (CEX)  ${percentDifference(
      onChainPrices[currency.name],
      curr.value
    )} `;
  } else return "No Price";
};
