import { createStore } from "solid-js/store";

export type PageStore = {
  per_page: number;
  current_page: number;
  startingIndex: number;
  endingIndex: number;
  total_pages: number;
  items_length: number;
};
interface StoreProps {
  map: Map<string, PageStore>;
}
// Initialize your store with some default values
const [store, setStore] = createStore<StoreProps>({ map: new Map() });

const doWork = (items_length: number, perPage: number, currentPage: number) => {
  const pages = Math.ceil(items_length / perPage);
  const startingIndex = (currentPage - 1) * perPage;
  const endingIndex = startingIndex + perPage;

  return { pages, startingIndex, endingIndex };
};

const setInitialValues = (key: string, ids: Array<any>, perPage: number) => {
  if (!store.map.has(key)) {
    const newMap = new Map(store.map);
    const { pages, startingIndex, endingIndex } = doWork(
      ids.length,
      perPage,
      1
    );
    newMap.set(key, {
      current_page: 1,
      total_pages: pages,
      startingIndex,
      endingIndex,
      per_page: perPage,
      items_length: ids.length,
    });
    setStore({
      map: newMap,
    });
  }
};

const setCurrentPage = (key: string, page: number) => {
  if (store.map.has(key)) {
    const { items_length, per_page } = store.map.get(key) as PageStore;
    const { pages, startingIndex, endingIndex } = doWork(
      items_length,
      per_page,
      page
    );
    const newMap = new Map(store.map);
    newMap.set(key, {
      current_page: page,
      total_pages: pages,
      startingIndex,
      endingIndex,
      per_page: per_page,
      items_length: items_length,
    });
    setStore({
      map: newMap,
    });
  }
};

// Export store and functions to interact with it

export default { store, setInitialValues, setCurrentPage };
