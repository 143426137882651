import { createSignal, createMemo, createEffect } from "solid-js";
import { VRSC_CURRENCY_ID } from "../CurrencyTable/Table";
import { useRouteData } from "solid-start";
import { routeData } from "~/routes/currencies";
import { For } from "solid-js";
import Modal from "../Modal/Modal";
import { estimateConversion } from "./utils/estimateConversion";

type ConversionModalType = {
  currency: any;
  closeModal: any;
};

const defaultValue = 1;

export function ConversionModal(props: ConversionModalType) {
  const { currency, closeModal } = props;
  const isReserve =
    currency.options % 2 === 1 || currency.id === VRSC_CURRENCY_ID;
  const { currency_data } = useRouteData<typeof routeData>();

  const [currency1, setCurrency1] = createSignal<string>("VRSC");
  const [amount1, setAmount1] = createSignal<number>();
  const [currency2, setCurrency2] = createSignal<string>("VRSC");
  const [amount2, setAmount2] = createSignal<number>();
  const [isRouted, setIsRouted] = createSignal<boolean>(false);
  const [via, setVia] = createSignal<Array<string>>([]);
  const [selectedVia, setSelectedVia] = createSignal<string>("");

  const [output, setOutput] = createSignal<Array<string>>([]);
  let select1: any;
  let select2: any;

  const handleConvert = async (
    from: string | undefined,
    destination: string,
    amount?: number
  ) => {
    if (!amount) return alert("Please enter an amount!");
    if (!destination) return alert("Select destination currency!");
    //if (via().length > 0 && !selectedVia()) return alert('Select route currency!')
    try {
      const conv = await estimateConversion(currency1(), currency2(), amount)

      setOutput(conv as any)
      // const { id } = await requestConversion(
      //   currency1(),
      //   currency2(),
      //   amount,
      //   selectedVia()
      // );

      //setAmount2(parseFloat(ras string));
    } catch (e) {
      console.log(e);
    }
  };

  createEffect(() => {
    if (currency1() && currency2()) {
      const filtered = currency_data()
        ?.currencies?.filter((f) => {
          const { rescurrlist } = f;
          if (rescurrlist && rescurrlist.length > 0) {
            return (
              rescurrlist?.includes(currency1()) &&
              rescurrlist?.includes(currency2())
            );
          }
          return;
        })
        .map((item) => item.name);
      setVia(filtered || []);
    }
  });

  const handleSwitch = () => {
    const amt1 = amount1();
    const amt2 = amount2();
    const curr1 = currency1();
    const curr2 = currency2();

    setCurrency1(curr2);
    setCurrency2(curr1);
    setAmount1(amt2);
    setAmount2(amt1);

    select1.value = curr2 || "VRSC";
    select2.value = curr1 || currency.name;
  };

  const handleSelectCurrency = (e: any, type: 1 | 2) => {
    switch (type) {
      case 1:
        setCurrency1(e.target.value);
        break;
      case 2:
        setCurrency2(e.target.value);
        if (!currency1()) setCurrency1(currency.name);
        break;
    }
  };

  return (
    <Modal
      closeModal={closeModal}
      content={
        <div class="relative w-full ">
          <div class="pagefooter-background flex flex-col items-center justify-center text-white rounded-lg w-full overflow-hidden">
            <h1 class="text-2xl font-bold mb-4 text-link-color">
              Estimate Conversion
            </h1>
            <div class="web3container-background p-4 rounded shadow-md w-full items-center flex flex-col justify-center">
              <div class="flex flex-col justify-around items-center m-2 w-full">
                <span>You pay</span>
                <div class="relative flex items-center bg-gray-700 rounded p-2 w-full">
                  <input
                    maxLength={15}
                    type="text"
                    placeholder={defaultValue.toString()}
                    class="bg-transparent focus:outline-none w-full h-[2rem]"
                    value={amount1() || 0}
                    onChange={(e) => setAmount1(parseFloat(e.target.value))}
                  />
                  <select
                    ref={select1}
                    onChange={(e) => handleSelectCurrency(e, 1)}
                    class="absolute w-20 right-2 bg-transparent focus:outline-none text-[0.75rem]"
                  >
                    <option class='bg-slate-500'>{currency.name}</option>
                    <For
                      each={currency_data()?.currencies?.filter(
                        (c: any) => c.name !== currency.name
                      )}
                    >
                      {(currency) => {
                        return (
                          <option class='bg-slate-500' value={currency.name}>{currency.name}</option>
                        );
                      }}
                    </For>
                  </select>
                </div>
              </div>
              <div class="h-fit flex justify-end ">
                <img
                  onClick={handleSwitch}
                  class="fill-white rotate-90 hover:cursor-pointer hover:outline hover:outline-2 active:outline-1 hover:outline-white rounded-full transition-all ease-in-out duration-200 p-3"
                  src="reverse.svg"
                  alt="reverse"
                />
              </div>
              <div class="flex flex-col justify-end items-end m-2 w-full">
                <span>You receive</span>
                <div class="relative flex items-center rounded p-2 w-full">

                  <div>
                    <select
                      ref={select2}
                      onChange={(e) => handleSelectCurrency(e, 2)}
                      class="absolute right-2 w-20 bg-transparent focus:outline-none text-[0.75rem]"
                      value={""}
                    >
                      <option class='bg-slate-500' value="VRSC">VRSC</option>
                      <For
                        each={currency_data()?.currencies?.filter(
                          (c: any) => c.name !== "VRSC"
                        )}
                      >
                        {(currency) => {
                          return (
                            <option class='bg-slate-500' value={currency.name}>{currency.name}</option>
                          );
                        }}
                      </For>
                    </select>
                  </div>
                </div>
              </div>
              <div class="flex flex-col items-start justify-start w-full flex-1 max-w-[600px]">
                <For each={output()}>
                  {
                    (o) => {
                      return <span>{o}</span>
                    }
                  }
                </For>
              </div>
              {via().length > 0 && currency1() !== "VRSC" && (
                <div class="flex flex-col items-center justify-center">
                  <div>Select Currency to route this estimation through:</div>
                  <div class='flex space-x-4 max-w-[400px] flex-wrap items-center justify-center'>
                    <For each={via()}>
                      {(option) => {
                        return (
                          <label
                            class="text-sm font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70 flex items-center gap-2"
                            for="option1"
                          >
                            <input
                              class="flex h-10 w-auto rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50"
                              id={option}
                              type="checkbox"
                              name={option}
                              value={option}
                              onChange={(e) => {
                                if (e.target.checked) {
                                  setSelectedVia(option);
                                }
                              }}
                            />
                            {option}
                          </label>
                        );
                      }}
                    </For>
                  </div>
                </div>
              )}
              <button
                onClick={() =>
                  handleConvert(currency1(), currency2() as string, amount1())
                }
                class="bg-[#22847B] hover:bg-[#22847B]/60 text-white p-2 mt-5 rounded w-72"
              >
                Calculate
              </button>
              <div class="mt-4 text-center text-gray-400">
                <p>All values are estimates.</p>
              </div>
              {isRouted() && (
                <div class="flex flex-col items-center justify-center">
                  <div class="font-bold">via:</div>
                  <div class="text-base font-bold">
                    {currency1()} =&gt; VRSC =&gt; {currency2()}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      }
    />
  );
}
