import { createSignal } from "solid-js";
import BaseTable from "../../components/BaseTable/BaseTable";
import { useNavigate, useRouteData } from "solid-start";
import { VRSC_CURRENCY_ID } from "../../components/CurrencyTable/Table";
import CurrencyModal from "../../components/CurrencyModal/CurrencyModal";
import { ConversionModal } from "../../components/ConversionModal/ConversionModal";
import calculatePercentDifference from "./utils/calculatePercentDifference";
import { CurrencyType } from "../../components/CurrencyType/CurrencyType";

export const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});

const smallNumberFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 5,
  maximumFractionDigits: 5,
});

const EconomyTable = () => {
  const [selectedCurrency, setSelectedCurrency] = createSignal<any>();
  const [showCurrencyModal, setShowCurrencyModal] = createSignal<boolean>();
  const navigate = useNavigate();
  const tableHeaders = ["Name", "On Chain Price", "Compared to CoinGecko (CG Price)", "Type", "Market Cap"];
  const mobileTableHeaders = ["Name", "On Chain Price", "Compared to CoinGecko (CG Price)", "Type"]
  const tableTabs = [
    {
      all: <button class="mr-20">All</button>,
    },
    {
      verus: <button class=" mr-20">Verus Currencies</button>,
    },

    {
      bridged: <button class="mr-20">Bridged Currencies</button>,
    },

    {
      pbaas: <button class="mr-20">PBaaS</button>,
    },
  ];

  const { currency_data } = useRouteData<typeof routeData>();

  if (!currency_data()) return <></>;

  const vrsc_curr = currency_data()?.currencies.find(
    (c) => c.currency_iaddr === VRSC_CURRENCY_ID
  );

  const { currencies } =
    currency_data() as any;

  const vrscCurr = currencies.find(
    (c: any) => c.currency_iaddr === VRSC_CURRENCY_ID
  );

  const filtandSort = currencies
    .filter((c: any) => {
      return c.currency_iaddr !== VRSC_CURRENCY_ID;
    })
    .sort((a: any, b: any) => {
      const aCap = a.onchainprice * a.supply
      const bCap = b.onchainprice * b.supply

      return bCap - aCap;
    });

  const allMapped = [vrscCurr, ...filtandSort].map((c: any) => {

    const vrscReserves = c.reservecurrencies?.find(
      (r: any) => r.currencyid === VRSC_CURRENCY_ID
    );
    return {
      data: {
        ...c,
        vrscReserves,
      },
      mobileMarkup: [
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base"
        >
          {c.name}
        </div>,
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base"
        >
          <div class="flex flex-col items-start justify-start gap-x-3">
            {c.onchainprice && (
              <div>
                {c.onchainprice < 0.01
                  ? smallNumberFormatter.format(
                    parseFloat(c.onchainprice?.toFixed(5))
                  )
                  : formatter.format(parseFloat(c.onchainprice.toFixed(2)))}
              </div>
            )}
          </div>
        </div>,
        <div>
          {c.cexprice && c.onchainprice && (
            <div class="flex flex-col">

              <div
                class={`${c.cexprice > c.onchainprice
                  ? "text-green-600"
                  : "text-red-600"
                  } text-lg`}
              >
                {calculatePercentDifference(
                  c.cexprice,
                  c.onchainprice
                ).toFixed(2)}
                %
              </div>
              <div class="text-sm">
                {c.cexprice < 0.01
                  ? smallNumberFormatter.format(
                    parseFloat(c.cexprice?.toFixed(5))
                  )
                  : formatter.format(parseFloat(c.cexprice.toFixed(2)))}
              </div>
            </div>
          )}
        </div>,
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base"
        >
          <CurrencyType currencyId={c.id} reserves={c.reservecurrencies} proof={c.proof} currency={c} vrscReserves={vrscReserves} />
        </div>
      ],
      markup: [
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base"
        >
          {c.name}
        </div>,
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base"
        >
          <div class="flex flex-col items-start justify-start gap-x-3">
            {c.onchainprice && (
              <div>
                {c.onchainprice < 0.01
                  ? smallNumberFormatter.format(
                    parseFloat(c.onchainprice?.toFixed(5))
                  )
                  : formatter.format(parseFloat(c.onchainprice.toFixed(2)))}
              </div>
            )}
          </div>
        </div>,
        <div>
          {c.cexprice && c.onchainprice && (
            <div class="flex flex-col">
              <div
                class={`${c.cexprice > c.onchainprice
                  ? "text-red-600"
                  : "text-green-600"
                  } text-lg`}
              >
                {calculatePercentDifference(c.cexprice, c.onchainprice).toFixed(
                  2
                )}
                %
              </div>
              <div class="text-sm">
                ({c.cexprice < 0.01
                  ? smallNumberFormatter.format(
                    parseFloat(c.cexprice?.toFixed(5))
                  )
                  : formatter.format(parseFloat(c.cexprice.toFixed(2)))})
              </div>
            </div>
          )}
        </div>,
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base"
        >
          <CurrencyType currencyId={c.id} reserves={c.reservecurrencies} proof={c.proof} currency={c} vrscReserves={vrscReserves} />

        </div>,
        <div
          onClick={() => setSelectedCurrency(c)}
          class="hover:cursor-pointer text-sm lg:text-base text-right flex items-end justify-end"
        >
          {c.onchainprice ? formatter.format(c.supply * c.onchainprice) : "N/A"}
        </div>,
      ],
    };
  });

  const verusMapped = [vrscCurr, ...filtandSort]
    .filter((c: any) => c.proof === 1)
    .map((c: any) => {
      const vrscReserves = c.reservecurrencies?.find(
        (r: any) => r.currencyid === VRSC_CURRENCY_ID
      );
      return {
        data: {
          ...c,
          vrscReserves,
        },
        mobileMarkup: [
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            {c.name}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <div class="flex flex-col items-start justify-start gap-x-3">
              {c.onchainprice && (
                <div>
                  {c.onchainprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.onchainprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.onchainprice.toFixed(2)))}
                </div>
              )}
            </div>
          </div>,
          <div>
            {c.cexprice && c.onchainprice && (
              <div class="flex flex-col">

                <div
                  class={`${c.cexprice > c.onchainprice
                    ? "text-green-600"
                    : "text-red-600"
                    } text-lg`}
                >
                  {calculatePercentDifference(
                    c.cexprice,
                    c.onchainprice
                  ).toFixed(2)}
                  %
                </div>
                <div class="text-sm">
                  {c.cexprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.cexprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.cexprice.toFixed(2)))}
                </div>
              </div>
            )}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <CurrencyType currencyId={c.id} reserves={c.reservecurrencies} proof={c.proof} currency={c} vrscReserves={vrscReserves} />
          </div>
        ],
        markup: [
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            {c.name}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <div class="flex flex-col items-start justify-start gap-x-3">
              {c.onchainprice && (
                <div>
                  {c.onchainprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.onchainprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.onchainprice.toFixed(2)))}
                </div>
              )}
            </div>
          </div>,
          <div>
            {c.cexprice && c.onchainprice && (
              <div class="flex flex-col">
                <div
                  class={`${c.cexprice > c.onchainprice
                    ? "text-red-600"
                    : "text-green-600"
                    } text-lg`}
                >
                  {calculatePercentDifference(
                    c.cexprice,
                    c.onchainprice
                  ).toFixed(2)}
                  %
                </div>
                <div class="text-sm">
                  ({c.cexprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.cexprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.cexprice.toFixed(2)))})
                </div>
              </div>
            )}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <CurrencyType currencyId={c.id} reserves={c.reservecurrencies} proof={c.proof} currency={c} vrscReserves={vrscReserves} />
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base text-right items-end justify-start"
          >
            {c.onchainprice ? formatter.format(c.supply * c.onchainprice) : "N/A"}
          </div>,
        ],
      };
    });

  const bridgedMapped = [vrscCurr, ...filtandSort]
    .filter((c: any) => c.proof === 3)
    .map((c: any) => {
      const vrscReserves = c.reservecurrencies?.find(
        (r: any) => r.currencyid === VRSC_CURRENCY_ID
      );

      return {
        data: {
          ...c,
          vrscReserves,
        },
        mobileMarkup: [
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            {c.name}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <div class="flex flex-col items-start justify-start gap-x-3">
              {c.onchainprice && (
                <div>
                  {c.onchainprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.onchainprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.onchainprice.toFixed(2)))}
                </div>
              )}
            </div>
          </div>,
          <div>
            {c.cexprice && c.onchainprice && (
              <div class="flex flex-col">

                <div
                  class={`${c.cexprice > c.onchainprice
                    ? "text-green-600"
                    : "text-red-600"
                    } text-lg`}
                >
                  {calculatePercentDifference(
                    c.cexprice,
                    c.onchainprice
                  ).toFixed(2)}
                  %
                </div>
                <div class="text-sm">
                  {c.cexprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.cexprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.cexprice.toFixed(2)))}
                </div>
              </div>
            )}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <CurrencyType currencyId={c.id} reserves={c.reservecurrencies} proof={c.proof} currency={c} vrscReserves={vrscReserves} />
          </div>
        ],
        markup: [
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            {c.name}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <div class="flex flex-col items-start justify-start gap-x-3">
              {c.onchainprice && (
                <div>
                  {c.onchainprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.onchainprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.onchainprice.toFixed(2)))}
                </div>
              )}
            </div>
          </div>,
          <div>
            {c.cexprice && c.onchainprice && (
              <div class="flex flex-col">
                <div
                  class={`${c.cexprice > c.onchainprice
                    ? "text-red-600"
                    : "text-green-600"
                    } text-lg`}
                >
                  {calculatePercentDifference(
                    c.cexprice,
                    c.onchainprice
                  ).toFixed(2)}
                  %
                </div>
                <div class="text-sm">
                  ({c.cexprice < 0.01
                    ? smallNumberFormatter.format(
                      parseFloat(c.cexprice?.toFixed(5))
                    )
                    : formatter.format(parseFloat(c.cexprice.toFixed(2)))})
                </div>
              </div>
            )}
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base"
          >
            <CurrencyType currencyId={c.id} reserves={c.reservecurrencies} proof={c.proof} currency={c} vrscReserves={vrscReserves} />
          </div>,
          <div
            onClick={() => setSelectedCurrency(c)}
            class="hover:cursor-pointer text-sm lg:text-base text-right items-end flex justify-end"
          >
            {c.onchainprice ? formatter.format(c.supply * c.onchainprice) : "N/A"}
          </div>,
        ],
      };
    });

  return (
    <div class="w-full lg:w-[60%] relative px-10 lg:mx-20">
      <BaseTable
        mobileTableHeaders={mobileTableHeaders}
        tableHeaders={tableHeaders}
        tableTabs={tableTabs}
        accessoryFunction={{
          "Estimate conversion": () => {
            setShowCurrencyModal(true);
          },
        }}
        tableRows={{
          all: allMapped,
          verus: verusMapped,
          bridged: bridgedMapped,
          pbaas: [],
        }}
      />
      {selectedCurrency() && (
        <CurrencyModal
          currency={selectedCurrency}
          closeModal={() => setSelectedCurrency(undefined)}
        />
      )}
      {showCurrencyModal() && (
        <ConversionModal
          currency={selectedCurrency() || vrsc_curr}
          closeModal={() => setShowCurrencyModal(false)}
        />
      )}
    </div>
  );
};

export default EconomyTable;
