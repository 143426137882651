import { Show } from "solid-js";
import { DesktopTable } from "../DesktopTable";
import { MobileTable } from "../MobileTable";

export const buildTable = (
  type: "all" | "verus" | "bridged",
  isMobile: any,
  currencies: any,
  usd_price: string,
  handleEstmateCurrency: any,
  handleShowCurrencyModal: any
) => {
  const getHeaderValues = () => {
    if (!isMobile) {
      switch (type) {
        case "all":
          return [
            "Name",
            "USD Price",
            "Reserves (%)",
            "Contract Address",
            "Type",
            "Rating",
            "",
          ];
        case "verus":
          return ["Name", "USD Price", "Reserves (%)", "Type", "Rating", ""];
        case "bridged":
          return ["Name", "USD Price", "Contract Addrss", "Rating", ""];
      }
    }
    return ["Name", "USD Price","Rating",""];
  };

  return (
    <Show
      when={isMobile}
      fallback={
        <DesktopTable
          type={type}
          headerValues={getHeaderValues()}
          usd_price={usd_price}
          currencies={currencies}
          handleEstimateCurrency={handleEstmateCurrency}
          handleShowCurrencyModal={handleShowCurrencyModal}
        />
      }
    >
      <MobileTable
        type={type}
        headerValues={getHeaderValues()}
        usd_price={usd_price}
        currencies={currencies}
        handleEstimateCurrency={handleEstmateCurrency}
        handleShowCurrencyModal={handleShowCurrencyModal}
      />
    </Show>
  );
};
