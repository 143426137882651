import { Layout } from "~/Layout/Layout";
import { createRouteData, useRouteData } from "solid-start";
import { useParams } from "solid-start";
import { fetchPool } from "~/utils/fetchPool";
import { For } from "solid-js";
import { Tooltip } from "~/components/Tooltip/Tooltip";
import fetchBaseUrl from "~/utils/fetchBaseUrl";

export function routeData() {
  const rawParam = useParams();
  const address_data = createRouteData(async () => {
    const pool = await fetchPool();
    const client = await pool?.connect();

    try {
      console.log(rawParam.address);
      const ids_owned = await client.query(
        `SELECT * FROM ownerbyaddress WHERE owner_address = '${rawParam.address}'`
      );
      const { owner_address, owned_ids } = ids_owned.rows[0] as any;
      console.log(ids_owned.rows);
      return {
        owner_address,
        owned_ids,
      };
    } catch (e) {
      client.release();
      return null;
    }
  });
  return { address_data };
}

export default function IdLookup() {
  const { address_data } = useRouteData<typeof routeData>();

  const handleClick = (name: string) => {
    window.location = `${fetchBaseUrl()}/idlookup/${name}` as any;
  };
  return (
    <Layout>
      <div class="relative p-10 text-white">
        <div class="text-2xl underline font-bold">
          {address_data()?.owner_address}
        </div>
        <div class="relative flex items-start flex-col my-10">
          <div class="text-xl fon-semibold mb-5">
            {address_data()?.owned_ids.length} {" "} IDs Owned by this address:{" "}
          </div>
          <div class="relative flex items-start justify-start max-w-[75%] flex-wrap">
            <For each={address_data()?.owned_ids}>
              {(item) => {
                return (
                  <div class="mr-8 my-5 border-2 border-accent-darkgreen p-5 rounded-[4px] hover:cursor-pointer hover:text-black hover:bg-[rgba(255,255,255,0.785)]" onClick={() => handleClick(item.fullname)}>
                    <div class="text-lg font-bold">{item.fullname}</div>
                    <Tooltip text={item.iaddr} placement="right">
                      <div class="font-light">
                        IAddress: {item.iaddr.substring(0, 8)}...
                      </div>
                    </Tooltip>
                  </div>
                );
              }}
            </For>
          </div>
        </div>
      </div>
    </Layout>
  );
}
