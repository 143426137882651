import { VRSC_CURRENCY_ID } from "../../../components/CurrencyTable/Table";
import { createSignal } from "solid-js";

const getWeightColor = (reserves: any, vrscReserves: any) => {
  if (reserves.length == 1 && vrscReserves.weight < 0.1) {
    return "text-red-600";
  }
};

export const renderReserves = (
  currency: any,
  reserves: any,
  vrscReserves: any
) => {
  const [detailsOpen, setDetailsOpen] = createSignal<boolean>(false)
  if (currency.currency_id === VRSC_CURRENCY_ID || (!reserves || reserves.length === 0)) {
    return (
      <span class="p-4 align-middle [&amp;:has([role=checkbox])]:pr-0 text-[#114853] italic">
        Not Applicable
      </span>
    );
  }
  return (
    <div class="relative">
      <div class="flex items-center justify-start gap-x-2" onMouseEnter={() => setDetailsOpen(true)} onMouseLeave={() => setDetailsOpen(false)} onTouchStart={() => setDetailsOpen(true)} onTouchEnd={() => setDetailsOpen(false)}>
        <span class="whitespace-nowrap">
          View Reserves
        </span>
        <span class="mt-1"> <img src="/caret_down.svg" class={`h-[25px] w-[25px] ${detailsOpen() ? "rotate-180" : ""}`} /></span>
      </div>
      {detailsOpen() && (
        <div class="flex items-center justify-center absolute top-6 left-0 bg-accent-darkgreen/90 p-5 rounded-[2px] z-20">
          <div class="flex flex-col gap-3">
            {reserves &&
              reserves.map((r: any, index: number) => {
                return (
                  <span class={`${getWeightColor(reserves, vrscReserves)} text-sm text-white font-black`}>
                    {r.reserves.toFixed(2)} {currency.rescurrlist[index]} (
                    {(r.weight * 100).toFixed(2)}%)
                  </span>
                );
              })}
          </div>
        </div>
      )}
    </div>



  );
};
