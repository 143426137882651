import { createContext, useContext, createSignal, createEffect } from "solid-js";
import { createWindowSize } from "@solid-primitives/resize-observer";
import { createStore } from "solid-js/store";
import { JSX } from "solid-js";
import { isServer } from "solid-js/web";
const DeviceSizeContext: any = createContext();

interface DeviceSizeProviderProps {
    children: JSX.Element
}

export function DeviceSizeProvider(props: DeviceSizeProviderProps) {
    const getSizes = (width: number) => {
        return {
            xs: width <= 500,
            s: width <= 768,
            tablet: width <= 1024,
            m: width <= 1280,
            lg: width <= 1440,
            xl: width > 1404

        }
    }

    const size = createWindowSize();

    const [deviceSize, setDeviceSize] = createSignal(getSizes(size.width));

    createEffect(() => {
        setDeviceSize(getSizes(size.width))
    })

    return (
        <DeviceSizeContext.Provider value={{ deviceSize }}>
            {props.children}
        </DeviceSizeContext.Provider>
    );
}

export function useDeviceSize() { return useContext(DeviceSizeContext); }