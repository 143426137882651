import { createStore } from "solid-js/store";
import { currency_notes } from "~/utils/currency_notes";

export type SortDirections = "ASC" | "DSC";

type SortMap = {
  direction: SortDirections;
  current_selection: string;
};

export type SortStore = {
  map: Map<string, SortMap>;
};

// Initialize your store with some default values
const [store, setStore] = createStore<SortStore>({
  map: new Map(),
});

const setInitialDirection = (
  key: string,
  type: string,
  direction: SortDirections
) => {
  if (!store.map.has(key)) {
    const newMap = new Map(store.map);
    newMap.set(key, {
      direction,
      current_selection: `${type}_${direction}`,
    });
    setStore({
      map: newMap,
    });
  }
};

const updateDirection = (
  key: string,
  type: string,
  direction: SortDirections
) => {
  if (store.map.has(key)) {
    const newMap = new Map(store.map);
    newMap.set(key, {
      direction,
      current_selection: `${type}_${direction}`,
    });
    setStore({
      map: newMap,
    });
  }
};

// Export store and functions to interact with it

export default { store, setInitialDirection, updateDirection };
